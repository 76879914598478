<template>
  <layout-content :class="$style.wrapBox">
    <template v-slot:header-left>
      <span :class="$style.title">
        {{ $t('menus.twins') }}
      </span>
    </template>
    <div slot="content">
      <iframe ref="iframeRef" :class="$style.wrap" :src="iframeLink"></iframe>
    </div>
  </layout-content>
</template>
<script>
import { Component, Vue, Watch } from 'vue-property-decorator';
import { crossStorageModule } from '@/enum/store';
import { LayoutContent } from '@triascloud/x-blocks';
import { twinsExternal } from '@/utils/externalHref';

const targeOrigin = 'https://iot-3d.triascloud.com';

@Component({
  components: {
    LayoutContent,
  },
})
export default class Twins extends Vue {
  @crossStorageModule.State skin;

  iframeLink = twinsExternal();
  iframeLoaded = false;
  mounted() {
    if (this.skin && this.skin.mode) {
      this.iframeOnload(this.skin);
    }
  }

  bgColor = '';
  @Watch('skin', { deep: true })
  onModeChange(val) {
    if (val && val.mode) {
      this.themeChange(val);
    }
  }

  iframeOnload(value) {
    this.$refs.iframeRef.onload = () => {
      this.iframeLoaded = true;
      this.postMessage(value);
    };
  }
  postMessage(value) {
    this.$refs.iframeRef.contentWindow.postMessage(
      JSON.stringify(value),
      targeOrigin,
    );
  }

  themeChange(skin) {
    if (this.iframeLoaded) {
      this.postMessage(skin);
    } else {
      this.iframeOnload(skin);
    }
  }
}
</script>

<style lang="less" module>
.wrap {
  width: 100%;
  height: 800px; // TODO 高度需要修改
  border: none;
}

.wrapBox {
  flex: 1;
  display: flex;
  overflow: hidden;
  .title {
    font-size: 16px;
  }
}
</style>
