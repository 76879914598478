/** @name 【数字孪生管理】的外部链接 */
export const twinsExternal = () => {
  const env = process.env.VUE_APP_ENV;
  /** 【dev】【sit】【uat】 */
  if (env !== 'production') {
    return 'https://iot-3d.triascloud.com/thing/external/operation/poclogin?loginCode=Sandieyuntest_IOT&targetUrl=/thing/auth/auth.html';
  } else {
    /** 【production】 */
    return 'https://iot-3d.triascloud.com/thing/external/operation/poclogin?loginCode=Sandieyun_IOT&targetUrl=/thing/auth/auth.html';
  }
};
